import {
  Button,
  Divider,
  Typography,
  Box,
  Autocomplete,
  TextField,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { IsRequired } from "../../components";
import { AlertProps, ipnumber } from "../../utils";
import { AlertContext } from "../../contexts";
import axios from "axios";
import { styled } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.common,
  },
  printcontent: {
    padding: "16px 16px",
    height: "calc(100% - 108px)",
    overflowY: "auto",
    background: theme.palette.background.table,
  },
  printcontentItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.common,
    borderTop: " 1px solid #e0e0e0",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    // padding: "16px",
    height: "52px",
    // overflow: "auto",
    background: theme.palette.background.common,
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .Mui-selected": {
    // backgroundColor: `${theme.palette.text.primary} !important`,
    // borderColor: `${theme.palette.text.primary} !important`,
    // color: `${theme.palette.text.primary} !important`,
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    /* "& .MuiToggleButton-label": {
      color: `${theme.palette.text.primary} !important`,
    }, */
  },
}));

export const AddEditDevice = ({
  isEdit = false,
  editData = null,
  onDrawerClose = () => false,
  giveMeDevice = () => false,
}) => {
  const [state, setState] = useState({
    printerid: isEdit ? editData?.printername : "",
    printername: isEdit ? editData?.printername : "",
    modeltype: isEdit ? editData?.modeltype : {},
    enterpriseid: isEdit ? editData?.enterpriseid : {},
    orgid: isEdit ? editData?.orgid : {},
    facilityid: isEdit ? editData?.facilityid : {},
    locationoftheprinter: isEdit ? editData?.locationoftheprinter : {},
    ipaddress: isEdit ? editData?.ipaddress : "",
    port: isEdit ? editData?.port : "",
    color: isEdit ? editData?.color : "",
    permission: isEdit ? editData?.permission : {},
  });
  const classes = useStyles();

  const [enterprise, setEnterprise] = React.useState([]);
  const [Organiztion, setOrganiztion] = React.useState([]);
  const [facility, setFacility] = React.useState([]);
  const [Location, setLocation] = React.useState([]);
  // const [userRole, setUserRole] = React.useState([]);

  const alertMessage = React.useContext(AlertContext);
  const projectdbname = sessionStorage.getItem("ProjectDbname");
  const metaId = sessionStorage.getItem("metaId");
  // console.log("editData", editData);
  // console.log("isEdit", isEdit);
  // React.useEffect(() => {
  //   if (!isEdit) {
  //     setState({});
  //   } else {
  //     setState({
  //       printerid: editData?.printername,
  //       printername: editData?.printername,
  //       modeltype: editData?.modeltype,
  //       locationoftheprinter: editData?.locationoftheprinter,
  //       ipaddress: editData?.ipaddress,
  //       port: editData?.port,
  //       color: editData?.color,
  //       permission: editData?.permission,
  //     });
  //   }
  //   //eslint-disable-next-line
  // }, []);

  React.useEffect(() => {
    giveMeEnterprise();
    // giveMeUserRole();
    if (isEdit) {
      giveMeOrg(state?.enterpriseid?.id);
      giveMeFacility(state?.orgid?.id);
      giveMeLocation(state?.facilityid?._id);
    }
    //eslint-disable-next-line
  }, []);

  const giveMeEnterprise = async () => {
    try {
      let params = {
        // db_name: `${atob(projectdbname)}`,
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "Organization",
        filter:
          "document(first(Organization.OrgType)).code=='OT001' && Organization.activestatus==true && Organization.active==true",
        return_fields:
          "keep(Organization,'_id','id','entitycode','name','isExternal')",
        sort: "Organization.name",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          setEnterprise(res.data.result);
        }
        //  else {
        //   alertMessage?.setSnack({
        //     ...alertMessage,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Something Went Wrong",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //   });
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const giveMeUserRole = async () => {
    try {
      let params = {
        db_name: `${atob(projectdbname)}`,
        entity: "IDM_Person",
        return_fields:
          "{_id:IDM_Person._id,username:IDM_Person.username,email:IDM_Person.email,roleid:IDM_Person.roleid}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          // setUserRole(res.data.result);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleChange = (key, value) => {
    debugger;
    if (key === "ipaddress") {
      let checkNumber = ipnumber(value);
      if (!checkNumber) {
        setState({ ...state, [key]: value });
      }
    } else if (key === "enterpriseid") {
      setState({
        ...state,
        [key]: value,
        orgid: {},
        facilityid: {},
        locationoftheprinter: {},
      });
      if (value?.id) {
        giveMeOrg(value.id);
      }
    } else if (key === "orgid") {
      setState({
        ...state,
        [key]: value,
        facilityid: {},
        locationoftheprinter: {},
      });
      if (value?.id) {
        giveMeFacility(value.id);
      }
    } else if (key === "facilityid") {
      setState({ ...state, [key]: value, locationoftheprinter: {} });
      if (value._id) {
        giveMeLocation(value._id);
        // giveMeLocation(state?.enterpriseid._id);
      }
    } else if (key === "locationoftheprinter") {
      setState({ ...state, [key]: value });
    } else {
      setState({ ...state, [key]: value });
    }
  };

  const giveMeOrg = async (id) => {
    try {
      let params = {
        // db_name: `${atob(projectdbname)}`,
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "Organization",
        filter: `first(Organization.ParentOrgID)==${id} && document(first(Organization.OrgType)).code=='OT002' && Organization.activestatus==true && Organization.active==true`,
        return_fields:
          "keep(Organization,'_id','id','entitycode','name','isExternal','ParentOrgID')",
        sort: "Organization.name",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          setOrganiztion(res.data.result);
        }
        //  else {
        //   alertMessage?.setSnack({
        //     ...alertMessage,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Something Went Wrong",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //   });
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const giveMeFacility = async (id) => {
    try {
      let params = {
        // db_name: `${atob(projectdbname)}`,
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "Organization",
        filter: `first(Organization.ParentOrgID)==${id} && document(first(Organization.OrgType)).code=='OT003' && Organization.activestatus==true && Organization.active==true`,
        return_fields:
          "keep(Organization,'_id','id','entitycode','name','isExternal','ParentOrgID')",
        sort: "Organization.name",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          setFacility(res.data.result);
        }
        //  else {
        //   alertMessage?.setSnack({
        //     ...alertMessage,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Something Went Wrong",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //   });
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const giveMeLocation = async (id) => {
    try {
      let params = {
        // db_name: `${atob(projectdbname)}`,
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "LocationMaster",
        filter: `LocationMaster.managingOrgID=='${id}' && LocationMaster.activestatus==true`,
        return_fields:
          "keep(LocationMaster,'id','_id','locationID','longdesc','managingOrgID','parentLocationID_desc','shortdesc')",
        sort: "LocationMaster.longdesc",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          setLocation(res.data.result);
        }
        //  else {
        //   alertMessage?.setSnack({
        //     ...alertMessage,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Something Went Wrong",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //   });
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newAlignment) => {
    setState({ ...state, color: newAlignment });
  };

  const isValid = (value) => {
    debugger;
    if (typeof value === "string") {
      return value?.trim()?.length === 0 ? false : true;
    } else if (typeof value === "object") {
      return Object.keys(value)?.length > 0 ? false : true;
    }
  };

  const VaildField = () => {
    let isPrintername = isValid(state?.printername);
    let ismodeltype = isValid(state?.modeltype?.name);
    let isipaddress = isValid(state?.ipaddress);

    if (isPrintername && ismodeltype && isipaddress) {
      return true;
    } else {
      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }
  };

  const onSaveClicked = () => {
    let validFields = VaildField();

    if (validFields) {
      if (isEdit) {
        // debugger;
        updatePrinterConfig();
      } else {
        upsertPrinterConfig();
      }
    }
  };

  const upsertPrinterConfig = async () => {
    try {
      let data = state;
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_configuration",
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: data,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          if (res?.data?.Code === 201) {
            onDrawerClose();
            giveMeDevice();
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Printer Configuration Added Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res.data.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((error) => {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `${error}`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } catch (error) {
      console.log(error);
      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: `${error}`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const updatePrinterConfig = async () => {
    try {
      let data = state;
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_configuration",
          filter: {
            _key: editData?._key,
          },
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            // printerid: state?.printerid ?? "",
            printername: data?.printername,
            modeltype: data?.modeltype,
            locationoftheprinter: data?.locationoftheprinter,
            ipaddress: data?.ipaddress,
            port: data?.port,
            color: data?.color,
            permission: data?.permission,
            enterpriseid: data?.enterpriseid,
            orgid: data?.orgid,
            facilityid: data?.facilityid,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          if (res.data.Result) {
            onDrawerClose();
            giveMeDevice();
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Printer Configuration Updated Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((error) => {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `Something Went Worng !`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } catch (error) {
      // console.log(error);
      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: `Something Went Worng !`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Box className={classes.Header2}>
        <Typography variant="body1">
          {isEdit ? "Update Device" : "Add Device"}
        </Typography>
      </Box>
      <Divider />
      <div className={classes.printcontent}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Print Name"}
              <IsRequired />
            </Typography>
            <TextField
              onChange={(e) => onHandleChange("printername", e.target.value)}
              value={state?.printername ?? ""}
              fullWidth
              placeholder="Enter Printer Name"
              id="textfield"
              variant="outlined"
              size="small"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Model"}
              <IsRequired />
            </Typography>
            <Autocomplete
              id="Printer Model"
              options={modelType ?? []}
              getOptionLabel={(option) => option.name || ""}
              value={state?.modeltype}
              fullWidth
              onChange={(e, value) => onHandleChange("modeltype", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  placeholder="Select Model Type"
                  //   error={error?.factcategoryerr}
                  //   helperText={
                  //     error?.factcategoryerr ? "This Field is Required*" : ""
                  //   }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Enterprise"}
            </Typography>
            <Autocomplete
              id="enterprise"
              options={enterprise ?? []}
              getOptionLabel={(option) => option.name || ""}
              value={state?.enterpriseid}
              fullWidth
              onChange={(e, value) => onHandleChange("enterpriseid", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  placeholder="Select Enterprise"
                  //   error={error?.factcategoryerr}
                  //   helperText={
                  //     error?.factcategoryerr ? "This Field is Required*" : ""
                  //   }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Organiztion"}
            </Typography>
            <Autocomplete
              id="Organiztion"
              options={Organiztion ?? []}
              getOptionLabel={(option) => option.name || ""}
              value={state?.orgid}
              fullWidth
              onChange={(e, value) => onHandleChange("orgid", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  placeholder="Select Organiztion"
                  //   error={error?.factcategoryerr}
                  //   helperText={
                  //     error?.factcategoryerr ? "This Field is Required*" : ""
                  //   }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Facility"}
            </Typography>
            <Autocomplete
              id="facility feild"
              options={facility ?? []}
              getOptionLabel={(option) => option.name || ""}
              value={state?.facilityid}
              fullWidth
              onChange={(e, value) => onHandleChange("facilityid", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  placeholder="Select Facility"
                  //   error={error?.factcategoryerr}
                  //   helperText={
                  //     error?.factcategoryerr ? "This Field is Required*" : ""
                  //   }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Location of the printer"}
            </Typography>
            <Autocomplete
              id="Printer Model"
              options={Location ?? []}
              getOptionLabel={(option) => option.longdesc || ""}
              value={state?.locationoftheprinter}
              fullWidth
              onChange={(e, value) =>
                onHandleChange("locationoftheprinter", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  placeholder="Select Location of the printer"
                  //   error={error?.factcategoryerr}
                  //   helperText={
                  //     error?.factcategoryerr ? "This Field is Required*" : ""
                  //   }
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="GrayText">
              {"Ip Address"}
              <IsRequired />
            </Typography>
            <TextField
              onChange={(e) => onHandleChange("ipaddress", e.target.value)}
              value={state?.ipaddress ?? ""}
              fullWidth
              placeholder="Enter Ip Address"
              id="textfield"
              variant="outlined"
              size="small"
              margin="dense"
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="GrayText">
              {"Port"}
              {/* <IsRequired /> */}
            </Typography>
            <TextField
              onChange={(e) => onHandleChange("port", e.target.value)}
              value={state?.port ?? ""}
              fullWidth
              type="number"
              //   placeholder="Enter Port Number"
              id="textfield"
              variant="outlined"
              size="small"
              margin="dense"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="body2" color="GrayText">
              {"Permission"}
              <IsRequired />
            </Typography>
            <Autocomplete
              id="Select User"
              options={userRole ?? []}
              getOptionLabel={(option) => option.username || ""}
              value={state?.permission}
              fullWidth
              onChange={(e, value) => onHandleChange("permission", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  placeholder="Select User"
                  //   error={error?.factcategoryerr}
                  //   helperText={
                  //     error?.factcategoryerr ? "This Field is Required*" : ""
                  //   }
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="body2" color="GrayText" gutterBottom>
              {"Colored"}
              {/* <IsRequired /> */}
            </Typography>
            <StyledToggleButtonGroup
              // color="primary"
              value={state?.color ?? ""}
              size="small"
              exclusive
              fullWidth
              //   onChange={(e)=>onHandleChange("color",e)}
              onChange={handleChange}
            >
              <ToggleButton value={"Yes"}>
                <Typography>YES</Typography>
              </ToggleButton>
              <ToggleButton value={"No"}>
                <Typography>NO</Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <Divider />
        <div className={classes.wrapperdiv2}>
          <Button onClick={() => onDrawerClose()} variant="outlined">
            {"Cancel"}
          </Button>
          <Button
            onClick={() => onSaveClicked()}
            variant="contained"
            sx={{ ml: "16px" }}
            className={classes.Button}
          >
            {isEdit ? "Update" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const modelType = [
  {
    name: "Inkjet printers",
  },
  {
    name: "Laser printers",
  },
  {
    name: "Solid ink printers",
  },
  {
    name: "Continuous ink printers",
  },
  {
    name: "LED printers",
  },
  {
    name: "Dot matrix printers",
  },
  {
    name: "A3 printers",
  },
  // "Inkjet printers",
  // "Laser printers",
  // "Solid ink printers",
  // "Continuous ink printers",
  // "LED printers",
  // "Dot matrix printers",
  // "A3 printers",
];

// const Permission = ["Super Admin", "Admin", "To All User"];
