import React from "react";
import {
  Button,
  Paper,
  Typography,
  Box,
  Divider,
  Grid,
  Modal,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Cards } from "../../components";
import { AlertContext, DialogContext } from "../../contexts";
import { AddEditQueue } from "./addEditQueue";
import axios from "axios";
import { AlertProps, performData } from "../../utils";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { CreateRule } from "ppq-rule-builder";
import { useHistory } from "react-router-dom";
// import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: theme.palette.background.common,
  },
  title: {
    display: "block",
    marginLeft: theme.spacing(1),
  },
  title1: {
    display: "block",
    marginLeft: "10px",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    background: theme.palette.background.table,
    borderBottom: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    // borderTop: "none",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  styledDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  content: {
    height: "calc(100% - 52px)",
    padding: theme.spacing(3),
  },
  Body: {
    height: "calc(100% - 52px)",
    padding: theme.spacing(3),
    overflowY: "scroll",
    background: theme.palette.background.table,
  },
  wrapper: {
    height: "100%",
    overflow: "hidden",
    borderRadius: "8px",
    border: "1px solid #dcdcdc",
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflowY: "scroll",
    background: theme.palette.background.table,
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  overflowY: "scroll",
  backgroundColor: "#fff",
};

export const PrinterQueueConfig = (props) => {
  const classes = useStyles();
  // const [toggle, setToggle] = React.useState(false);
  const dialogContext = React.useContext(DialogContext);
  const alertMessage = React.useContext(AlertContext);
  // const [isdisable, SetDiasbled] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [ruleOpen, setRuleOpen] = React.useState(false);
  const [ruleData, setRuleData] = React.useState({});
  const [inputSchemaList, setState] = React.useState([]);
  //const onChangeState = (key, value) => {
  //  props.updateState(key, value);
  //};
  // const handleOpen = () => setOpen(true);
  const onRuleModelOpen = (data) => {
    setRuleOpen(true);
    setRuleData(data);
    console.log(data);
    setState(JSON.parse(data?.inputschema[0]?.inputschema));
    setEdit({
      isEdit: true,
      editData: data,
    });
  };
  // const handleClose = () => setOpen(false);
  const onRuleModelClose = () => setRuleOpen(false);
  const [data, setData] = React.useState([]);
  const [deviceData, setDeviceData] = React.useState([]);
  const [edit, setEdit] = React.useState({
    isEdit: false,
    editData: null,
  });

  const histroy = useHistory();

  const projectdbname = sessionStorage.getItem("ProjectDbname");
  const metaId = sessionStorage.getItem("metaId");

  React.useEffect(() => {
    giveMePrinterQueue();
    giveMeDevice();
    // eslint-disable-next-line
  }, []);

  const giveMePrinterQueue = async () => {
    try {
      let params = {
        db_name: `${atob(projectdbname)}`,
        entity: "printer_queue_configuration",
        filter: "printer_queue_configuration.activestatus==true",
        return_fields: "printer_queue_configuration",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        // console.log(res.data.result);
        if (res.data.result) {
          setData(res.data.result);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSaveClicked = (data) => {
    // console.log(data)
    if (!data) {
      setRuleOpen(false);
    } else {
      debugger;
      let ruleid = data?.Result[0]?.properties?.doc?._id;
      // console.log(ruleid)
      //props.updateState("queueentrycriteria", ruleid)

      //console.log();
      UpdateCreatequeue(ruleid);
    }
  };
  const UpdateCreatequeue = async (ruleid) => {
    try {
      debugger;
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_queue_configuration",
          filter: {
            _key: edit?.editData?._key,
          },
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            queueentrycriteria: ruleid,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          console.log(res.data.Result);

          giveMePrinterQueue();
          onRuleModelClose();
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onAddQueueClicked = () => {
    histroy.push({
      pathname: Routes.addPrinterQueue,
      search: `metadata_id=${props?.state?.metadataId}&token=${props?.state?.token}`,
    });
  };

  const onEditClicked = (data) => {
    histroy.push({
      pathname: Routes.editPrinterQueue + btoa(data?._id),
      search: `metadata_id=${props?.state?.metadataId}&token=${props?.state?.token}`,
      state: data,
    });
  };

  const onDeleteClicked = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to remove this queue?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => confirmDelete(data._key),
    });
  };

  const confirmDelete = (key) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    softDelete(key);
  };
  const giveMeDevice = async () => {
    try {
      let params = {
        db_name: `${atob(projectdbname)}`,
        entity: "printer_configuration",
        filter: "printer_configuration.activestatus==true",
        return_fields: "printer_configuration",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.result) {
          setDeviceData(res.data.result);
          //sessionStorage.setItem("printerlist",res.data.result);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const softDelete = async (_key) => {
    try {
      let params = {
        db_name: `${atob(projectdbname)}`,
        entity: "printer_queue_configuration",
        filter: `printer_queue_configuration._key == '${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Queue Removed Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        giveMePrinterQueue();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.Header}>
        <Typography> Printer Queue Configuration</Typography>
        {/* <div className={classes.container}></div> */}
      </Box>
      <div className={classes.content}>
        <Paper className={classes.wrapper} elevation={0}>
          <React.Fragment>
            <Box className={classes.Header}>
              <Typography variant="body1" sx={{ p: "8px 0" }}>
                Queue's
              </Typography>
              <Button
                id={"Add Schema"}
                onClick={() => onAddQueueClicked()}
                variant="text"
                // color="info"
              >
                {"+ Add Queue"}
              </Button>
            </Box>
            <Box className={classes.Body}>
              <Grid container spacing={1}>
                {data?.map((list, index) => (
                  <Cards
                    type="1"
                    isNumbarTag
                    index={index}
                    title={list?.queuename ?? ""}
                    entrycriteria={list?.queueentrycriteria ?? []}
                    data={list}
                    job
                    jobPending
                    jobDone
                    active
                    showEditIcon
                    showDeleteIcon
                    onRuleModelOpen={onRuleModelOpen}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={onDeleteClicked}
                  />
                ))}
              </Grid>
            </Box>
          </React.Fragment>
        </Paper>
      </div>

      <Modal
        open={ruleOpen}
        onClose={onRuleModelClose}
        aria-labelledby="modal-modal-title////"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          <CreateRule //inputSchema={{}}
            inputSchema={inputSchemaList}
            editId={[ruleData?.queueentrycriteria] ?? []}
            handleClose={(data) => {
              onSaveClicked(data);
            }}
            open={true}
            performvalueData={{
              queueList: data,
              //
              printerList: deviceData,
              userRole: JSON.parse(sessionStorage.getItem("userAssignee")),
              userGroup: JSON.parse(sessionStorage.getItem("userGroup")),
            }}
            performKeyData={performData}
            dbName={`${process.env.REACT_APP_DB}`}
          ></CreateRule>
        </Box>
      </Modal>
    </div>
  );
};

/* const lists = [
  {
    queueid: "Q1",
    queuename: "Medication",
    queueconditions: "any",
    queuedescription: "",
    printerid: "",
    queuegroup: "",
    queueentrycriteria: "",
    inputschema: [],
    idmid: "",
    status: true,
  },
  {
    queueid: "Q2",
    queueconditions: "any",
    queuedescription: "",
    printerid: "",
    queuegroup: "",
    queueentrycriteria: "",
    inputschema: [],
    IDMid: "",
    queuename: "Filling",
    status: true,
  },
  {
    queueid: "Q3",
    queueconditions: "all",
    queuedescription: "",
    printerid: "",
    queuegroup: "",
    queueentrycriteria: "",
    inputschema: [],
    IDMid: "",
    queuename: "Sequence",
    status: true,
  },
]; */
