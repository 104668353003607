import { Button, Box, Divider, Stack } from "@mui/material";
import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { InputSchema } from "./inputSchema";
import { QueueDetails } from "./QueueDetails";
import { AlertProps } from "../../utils";
import axios from "axios";
import { AlertContext } from "../../contexts";
import { PropaneSharp } from "@mui/icons-material";

export const AddEditQueue = (props) => {
  const [value, setValue] = React.useState("1");
  const alertMessage = React.useContext(AlertContext);
  const projectdbname = sessionStorage.getItem("ProjectDbname");
  const metaId = sessionStorage.getItem("metaId");

  const [state, setState] = React.useState({
    queueid: props?.editData?.queueid ?? "",
    queuename: props?.editData?.queuename ?? "",
    queueconditions: props?.editData?.queueconditions ?? "",
    queuedescription: props?.editData?.queuedescription ?? "",
    printerid: props?.editData?.printerid ?? "",
    queuegroup: props?.editData?.queuegroup ?? "",
    queueentrycriteria: props?.editData?.queueentrycriteria ?? "",
    inputschema: props?.editData?.inputschema ?? [],
    idmid: props?.editData?.idmid ?? "",
  });

  const handleTab = () => {
    switch (value) {
      case "1":
        setValue("2");
        break;
      default:
        break;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const isValid = (value) => {
    return value?.trim()?.length === 0 ? false : true;
  };

  const VaildField = () => {
    debugger;
    let isqueuename = isValid(state?.queuename);
    let isinputschema = state?.inputschema?.length === 0 ? false : true;
    let isidmid = isValid(state?.idmid);

    if (isqueuename && isinputschema && isidmid) {
      return true;
    } else {
      if (!isqueuename) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Queue Name is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (!isinputschema) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Inputschema is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (!isidmid) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "IDM Id is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }

      return false;
    }
  };

  const onSaveClicked = () => {
    let validFields = VaildField();
    let duplicateCheck = props.data?.filter(
      (list) =>
        list?.queuename?.toLowerCase() === state?.queuename?.toLowerCase()
    );
    if (validFields) {
      if (props?.isEdit){
        updateQueue(state);
      } else {
        if (duplicateCheck.length !== 0) {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Queue Name already exists",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          upsertQueue(state);
          //alertMessage?.setSnack({
            //...alertMessage,
            // open: true,
            // severity: AlertProps.severity.error,
            // msg: "Queue Name already exists",
            // vertical: AlertProps.vertical.top,
            // horizontal: AlertProps.horizontal.center,
          // });
        // } else {
          // upsertQueue(state);
        // }
        }
      }
    }
  };

  const upsertQueue = async (state) => {
    try {
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_queue_configuration",
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: state,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        props.giveMePrinterQueue();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Queue Added Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        props.onAddQueueClicked();
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateQueue = async (state) => {
    debugger;
    try {
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_queue_configuration",
          filter: {
            _key: props?.editData?._key,
          },
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            queueid: state?.queueid ?? "",
            queuename: state?.queuename ?? "",
            queueconditions: state?.queueconditions ?? "",
            queuedescription: state?.queuedescription ?? "",
            printerid: state?.printerid ?? "",
            queuegroup: state?.queuegroup ?? "",
            queueentrycriteria: state?.queueentrycriteria ?? "",
            inputschema: state?.inputschema ?? [],
            idmid: state?.idmid ?? "",
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        props.giveMePrinterQueue();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Queue Updated Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        props.onAddQueueClicked();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <TabContext value={value}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={1}
          paddingRight={1}
        >
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: {
                  height: "4px",
                  borderRadius: "5px 5px 0px 0px",
                },
              }}
            >
              <Tab value="1" label="Input Schema" />
              <Tab value="2" label="Queue Details" />
            </TabList>
          </Box>
          <div style={{ display: "flex", marginRight: "16px" }}>
            <Button
              id={"Back Button"}
              onClick={() => props.onAddQueueClicked()}
              variant="outlined"
            >
              {props?.isEdit ? "Back" : "Cancel"}
            </Button>
            {value === "2" ? (
              <Button
                id={"Save Button"}
                onClick={() => onSaveClicked()}
                variant="contained"
                sx={{ ml: "16px" }}
              >
                {props?.isEdit ? "Update" : "Save"}
              </Button>
            ) : (
              <Button
                id={"Next Button"}
                onClick={() => handleTab()}
                variant="contained"
                sx={{ ml: "16px" }}
              >
                {"Next"}
              </Button>
            )}
          </div>
        </Stack>
        <Divider></Divider>
        <TabPanel
          style={{ height: "calc(100% - 52px)", overflowY: "auto" }}
          value="1"
        >
          <InputSchema  state={state} updateState={updateState} />
        </TabPanel>
        <TabPanel
          style={{ height: "calc(100% - 52px)", overflowY: "auto" }}
          value="2"
        >
          <QueueDetails editData={props.editData} data={props.data} isEdit={props.isEdit} state={state} updateState={updateState} />
        </TabPanel>
      </TabContext>
    </React.Fragment>
  );
};
