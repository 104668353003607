import {
  Button,
  Box,
  Divider,
  Stack,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { InputSchema } from "./inputSchema";
import { QueueDetails } from "./QueueDetails";
import { AlertProps } from "../../utils";
import axios from "axios";
import { AlertContext } from "../../contexts";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: theme.palette.background.common,
  },
  title: {
    display: "block",
    marginLeft: theme.spacing(1),
  },
  title1: {
    display: "block",
    marginLeft: "10px",
  },
  Header: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.background.table,
    borderBottom: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    // borderTop: "none",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    background: theme.palette.background.table,
    // background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  content: {
    height: "calc(100% - 52px)",
    padding: theme.spacing(3),
  },
  tabs: {
    height: "calc(100% - 52px)",
    overflowY: "auto",
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  icon: {
    width: "30px",
    height: "30px",
    // boxShadow: "0 0 2px rgb(184 184 184)",
    borderRadius: "50%",
    padding: "4px",
    color: theme.palette.text.primary,
  },
}));

export const AddEditPrinterQueue = (props) => {
  const { isEdit } = props;
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const alertMessage = React.useContext(AlertContext);
  const projectdbname = sessionStorage.getItem("ProjectDbname");
  const metaId = sessionStorage.getItem("metaId");
  const [check, setCheck] = React.useState(false);
  const { id } = useParams();

  const pass = isEdit ? atob(id?.slice(3)) : "";

  console.log(pass);

  const [state, setState] = React.useState({
    queueid: "",
    queuename: "",
    queueconditions: "",
    queuedescription: "",
    printerid: "",
    queuegroup: "",
    queueentrycriteria: "",
    inputschema: [],
    idmid: "",
  });

  React.useEffect(() => {
    if (isEdit) {
      getSingleQueue();
    }
    //eslint-disable-next-line
  }, []);

  const getSingleQueue = async () => {
    try {
      let params = {
        db_name: `${atob(projectdbname)}`,
        entity: "printer_queue_configuration",
        filter: `printer_queue_configuration._id=='${pass}'&& printer_queue_configuration.activestatus==true`,
        return_fields: "printer_queue_configuration",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          // console.log(res.data.result[0])
          if (res.data.Code === 201) {
            setState(res.data.result[0]);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res.data.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          // alert("Something Went Wrong");
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTab = () => {
    switch (value) {
      case "1":
        setValue("2");
        break;
      default:
        break;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateState = (key, value) => {
    if (isEdit && key === "queuename") {
      setState({ ...state, [key]: value });
      setCheck(true);
    } else {
      setState({ ...state, [key]: value });
    }
  };

  const isValid = (value) => {
    return value?.trim()?.length === 0 ? false : true;
  };

  const onBackClicked = () => {
    setValue("1");
  };

  const VaildField = () => {
    debugger;
    let isqueuename = isValid(state?.queuename);
    let isinputschema = state?.inputschema?.length === 0 ? false : true;
    // let isidmid = isValid(state?.idmid);

    if (isqueuename && isinputschema) {
      return true;
    } else {
      if (!isqueuename) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Queue Name is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (!isinputschema) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Inputschema is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        // } else if (!isidmid) {
        //   alertMessage?.setSnack({
        //     ...alertMessage,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "IDM Id is Empty",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //   });
      }

      return false;
    }
  };

  const backTOQueueConfig = () => {
    history.goBack();
  };

  const onSaveClicked = () => {
    let validFields = VaildField();
    if (validFields) {
      if (isEdit) {
        if (check) {
          let params = {
            db_name: `${atob(projectdbname)}`,
            entity: "printer_queue_configuration",
            filter: `printer_queue_configuration.activestatus==true && UPPER(printer_queue_configuration.queuename) == '${state?.queuename.toUpperCase()}'`,
            return_fields: "printer_queue_configuration",
          };
          let config = {
            method: "post",
            url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
            headers: {
              "Content-Type": "application/json",
            },
            data: params,
          };

          axios(config).then((res) => {
            let _data = res.data.result;
            if (_data.length === 0) {
              updateQueue();
            } else {
              alertMessage.setSnack({
                ...alertMessage,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Scenario Name Already Exists !",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            }
          });
        } else {
          updateQueue();
        }
      } else {
        let params = {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_queue_configuration",
          filter: `printer_queue_configuration.activestatus==true && UPPER(printer_queue_configuration.queuename) == '${state?.queuename.toUpperCase()}'`,
          return_fields: "printer_queue_configuration",
        };
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        axios(config).then((res) => {
          let _data = res.data.result;
          if (_data.length === 0) {
            upsertQueue();
          } else {
            alertMessage.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Scenario Name Already Exists !",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        });
      }
    }
  };

  const upsertQueue = async () => {
    try {
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_queue_configuration",
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: state,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Queue Added Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        history.goBack();
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateQueue = async () => {
    try {
      let params = [
        {
          db_name: `${atob(projectdbname)}`,
          entity: "printer_queue_configuration",
          filter: {
            _key: state?._key,
          },
          is_metadata: true,
          metadataId: `${atob(metaId)}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            queueid: state?.queueid ?? "",
            queuename: state?.queuename ?? "",
            queueconditions: state?.queueconditions ?? "",
            queuedescription: state?.queuedescription ?? "",
            printerid: state?.printerid ?? "",
            queuegroup: state?.queuegroup ?? "",
            queueentrycriteria: state?.queueentrycriteria ?? "",
            inputschema: state?.inputschema ?? [],
            idmid: state?.idmid ?? "",
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Queue Updated Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        history.goBack();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.Header}>
        <IconButton onClick={() => backTOQueueConfig()}>
          <ArrowBackIcon className={classes.icon} />
          {/* <ArrowCircleLeftOutlinedIcon /> */}
        </IconButton>
        <Typography>
          {props?.isEdit ? state?.queuename : "Queue Configuration"}
        </Typography>
        {/* <div className={classes.container}></div> */}
      </Box>
      <div className={classes.content}>
        <Paper
          style={{
            height: "100%",
            overflowY: "scroll",
            borderRadius: "8px",
            border: "1px solid #dcdcdc",
          }}
          elevation={0}
        >
          <TabContext value={value}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingLeft={1}
              paddingRight={1}
              className={classes.Header2}
            >
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    style: {
                      height: "4px",
                      borderRadius: "5px 5px 0px 0px",
                    },
                  }}
                >
                  <Tab value={"1"} label="Input Schema" />
                  <Tab value={"2"} label="Queue Details" />
                </TabList>
              </Box>
              <div style={{ display: "flex", marginRight: "16px" }}>
                {value === "2" ? (
                  <>
                    <Button
                      id={"Back Button"}
                      onClick={() => onBackClicked()}
                      variant="outlined"
                    >
                      {"Back"}
                    </Button>
                    <Button
                      id={"Save Button"}
                      onClick={() => onSaveClicked()}
                      variant="contained"
                      sx={{ ml: "16px" }}
                      className={classes.Button}
                    >
                      {props?.isEdit ? "Update" : "Save"}
                    </Button>
                  </>
                ) : (
                  <Button
                    id={"Next Button"}
                    onClick={() => handleTab()}
                    variant="contained"
                    sx={{ ml: "16px" }}
                    className={classes.Button}
                  >
                    {"Next"}
                  </Button>
                )}
              </div>
            </Stack>
            <Divider></Divider>
            <TabPanel className={classes.tabs} value={"1"}>
              <InputSchema state={state} updateState={updateState} />
            </TabPanel>
            <TabPanel
              className={classes.tabs}
              // style={{ height: "calc(100% - 52px)", overflowY: "auto" }}
              value={"2"}
            >
              <QueueDetails
                editData={props.editData}
                data={props.data}
                isEdit={props.isEdit}
                state={state}
                updateState={updateState}
              />
            </TabPanel>
          </TabContext>
        </Paper>
      </div>
    </div>
  );
};
